import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      pattern: file(relativePath: { eq: "pattern.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <footer className="text-white text-center text-lg-start footer">
      <div className="container">
        <Link to={`/`}>
          <img src="/images/dioiz_logo_footer.svg" alt="" />
        </Link>
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
            <ul className="mb-0 space-y-1 text-gray-300">
              <li>20th Floor, 149, Sejong-daero, Jongno-gu, </li>
              <li>Seoul, Korea, 〒03186</li>
              <li>Tel +82-2-785-5709</li>
              <li>Fax +82-2-723-5900</li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <ul className="list-unstyled mb-0">
              <li>
                <Link to={`/product_embedded_wizard/`} className="text-white">
                  Embedded Wizard
                </Link>
              </li>
              <li>
                <Link to={`/product_safertos/`} className="text-white">
                  SAFERTOS
                </Link>
              </li>
              {/* <li>
              <Link to={`/product_openrtos/`} className="text-white">
                OPENRTOS
              </Link>
            </li> */}
              {/* <li>
              <Link to={`/product_tracealyzer/`} className="text-white">
                TraceAlyzer
              </Link>
            </li> */}
              <li>
                <Link to={`/product_cyclonetcp/`} className="text-white">
                  CycloneTCP
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <ul className="list-unstyled">
              <li>
                <Link to={`/about_us/`} className="text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link to={`/blog/`} className="text-white">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center copyright">
        Copyright © 2023 DIOIZ Co., Ltd. All Rights Reserved.
      </div>
      <div className="back">
        <GatsbyImage
          image={data.pattern.childImageSharp.gatsbyImageData}
          alt=""
          style={{ height: "100%" }}
        />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
