import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className="shadow-sm">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light py-4">
        <div className="container-fluid">
          <Link to={`/`}>
            <img src="/images/dioiz_logo_header.svg" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  製品一覧
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/product_embedded_wizard"
                    >
                      Embedded Wizard
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/product_safertos">
                      SAFERTOS
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/product_openrtos">
                      OPENRTOS
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link className="dropdown-item" to="/product_tracealyzer">
                      TraceAlyzer
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/product_cyclonetcp">
                      CycloneTCP
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about_us/">
                  会社概要
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog/">
                  ブログ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
